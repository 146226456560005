class Browser {
    isOpera = false;
    isFirefox = false;
    isSafari = false;
    isIE = false;
    isEdge = false;
    isChrome = false;
    isBlink = false;
    isArc = false;
    constructor() {
        if (typeof window !== 'undefined') {
            this.isOpera =
                (!!window.opr && !!opr.addons) ||
                    !!window.opera ||
                    navigator.userAgent.indexOf(' OPR/') >= 0;
            this.isFirefox = typeof InstallTrigger !== 'undefined';
            this.isSafari =
                /constructor/i.test(window.HTMLElement) ||
                    (function (p) {
                        return p.toString() === '[object SafariRemoteNotification]';
                    })(!window['safari'] ||
                        (typeof safari !== 'undefined' && safari.pushNotification));
            this.isIE = false || !!document.documentMode;
            this.isEdge = !this.isIE && !!window.StyleMedia;
            this.isChrome =
                !!window.chrome &&
                    (!!window.chrome.webstore || !!window.chrome.runtime);
            this.isBlink = (this.isChrome || this.isOpera) && !!window.CSS;
            this.isArc = getComputedStyle(document.documentElement).getPropertyValue('--arc-palette-title');
        }
    }
}
export default new Browser();
