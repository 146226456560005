import EventEmitter from './events';
import utils from '@crossmarkio/utils';
class Mount extends EventEmitter {
    isCrossmark;
    crossmark = undefined;
    #sdk;
    constructor(sdk) {
        super();
        this.#sdk = sdk;
        this.loop(10000);
    }
    loop = async (timeout) => new Promise(async (resolve, _reject) => {
        if (this.isCrossmark)
            resolve(true);
        let now = Date.now();
        while (true) {
            if (typeof window !== 'undefined' && window.xrpl && window.xrpl.isCrossmark) {
                this.isCrossmark = window.xrpl.isCrossmark;
                window.crossmark = Object.assign({}, window?.crossmark, this.#sdk);
                this.emit('detected');
                this.#sdk.emit('detected');
                resolve(true);
                break;
            }
            await utils.helpers.sleep(500);
            if (Date.now() > now + timeout) {
                resolve(false);
                break;
            }
        }
    });
}
export default Mount;
