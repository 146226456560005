import EventEmitter from 'events';
import typings from '@crossmarkio/typings';
class xEventsEmitter extends EventEmitter {
    constructor() {
        super();
    }
}
export class EventManager extends xEventsEmitter {
    sdk;
    api;
    constructor(sdk) {
        super();
        this.sdk = sdk;
        this.api = this.sdk.api;
        this.api.on(typings.extension.Extension.EVENTS.PING, () => this.sdk.emit(typings.extension.Extension.EVENTS.PING));
        this.api.on(typings.extension.Extension.EVENTS.RESPONSE, (resp) => {
            this.sdk.emit(typings.extension.Extension.EVENTS.RESPONSE, resp);
            this.sdk.emit(typings.extension.Extension.EVENTS.ALL, {
                type: typings.extension.Extension.EVENTS.RESPONSE,
                resp,
            });
        });
        this.api.on(typings.extension.Extension.EVENTS.USER_CHANGE, (user) => {
            this.sdk.emit(typings.extension.Extension.EVENTS.USER_CHANGE, user);
            this.sdk.emit(typings.extension.Extension.EVENTS.ALL, {
                type: typings.extension.Extension.EVENTS.USER_CHANGE,
                user,
            });
        });
        this.api.on(typings.extension.Extension.EVENTS.NETWORK_CHANGE, (network) => {
            this.sdk.emit(typings.extension.Extension.EVENTS.NETWORK_CHANGE, network);
            this.sdk.emit(typings.extension.Extension.EVENTS.ALL, {
                type: typings.extension.Extension.EVENTS.NETWORK_CHANGE,
                network,
            });
        });
        this.api.on(typings.extension.Extension.EVENTS.OPEN, () => {
            this.sdk.emit(typings.extension.Extension.EVENTS.OPEN);
            this.sdk.emit(typings.extension.Extension.EVENTS.ALL, {
                type: typings.extension.Extension.EVENTS.OPEN,
            });
        });
        this.api.on(typings.extension.Extension.EVENTS.CLOSE, () => {
            this.sdk.emit(typings.extension.Extension.EVENTS.CLOSE);
            this.sdk.emit(typings.extension.Extension.EVENTS.ALL, {
                type: typings.extension.Extension.EVENTS.CLOSE,
            });
        });
        this.api.on(typings.extension.Extension.EVENTS.SIGNOUT, () => {
            this.sdk.emit(typings.extension.Extension.EVENTS.SIGNOUT);
            this.sdk.emit(typings.extension.Extension.EVENTS.ALL, {
                type: typings.extension.Extension.EVENTS.SIGNOUT,
            });
        });
    }
}
export default xEventsEmitter;
