import EventEmitter from './events';
import utils from '@crossmarkio/utils';
import typings from '@crossmarkio/typings';
class Api extends EventEmitter {
    active = new Map();
    uuid;
    connected;
    target;
    timestamp;
    constructor() {
        super();
        this.uuid = utils.helpers.uuid();
        this.connected = false;
        this.timestamp = Date.now();
        if (typeof window !== 'undefined') {
            this.target = window.origin;
            window.addEventListener('message', this.#handleMsg);
        }
    }
    #handleMsg = (event) => {
        try {
            if (!window)
                return;
            if (window &&
                (event.source !== window || !event.source || event.origin !== window.location.origin))
                return;
            if (!event.data)
                return;
            let type = event.data?.type || undefined;
            let resp = event.data?.response || undefined;
            if (type === typings.extension.Extension.TYPES.UPDATE)
                return;
            if (type === typings.extension.Extension.TYPES.EVENT && 'type' in event.data)
                return this.#handleEvent(event.data);
            if (type === 'request')
                return;
            if (resp && resp.type === typings.extension.Extension.TYPES.RESPONSE)
                this.emit(typings.extension.Extension.EVENTS.RESPONSE, event.data);
            if (resp &&
                resp.type === typings.extension.Extension.EVENTS.RESPONSE &&
                this.active.get(resp.id)) {
                return this.active.get(resp.id)?.resolve(event.data);
            }
        }
        catch (e) {
            return;
        }
    };
    #handleEvent = (e) => {
        if (e.event === typings.extension.Extension.EVENTS.PING)
            this.emit(typings.extension.Extension.EVENTS.PING);
        if (e.event === typings.extension.Extension.EVENTS.CLOSE)
            this.emit(typings.extension.Extension.EVENTS.CLOSE);
        if (e.event === typings.extension.Extension.EVENTS.OPEN)
            this.emit(typings.extension.Extension.EVENTS.OPEN);
        if (e.event === typings.extension.Extension.EVENTS.SIGNOUT)
            this.emit(typings.extension.Extension.EVENTS.SIGNOUT);
        if (e.event === typings.extension.Extension.EVENTS.USER_CHANGE)
            this.emit(typings.extension.Extension.EVENTS.USER_CHANGE, e.data);
        if (e.event === typings.extension.Extension.EVENTS.NETWORK_CHANGE)
            this.emit(typings.extension.Extension.EVENTS.NETWORK_CHANGE, e.data);
    };
    #fire = async (request) => {
        let response = await new Promise((resolve, reject) => {
            this.active.set(request.id, {
                resolve: resolve,
                reject: reject,
            });
            if (window)
                window.postMessage(request);
        });
        this.active.delete(request.id);
        return response;
    };
    awaitRequest = async (request) => {
        try {
            let res = await this.#fire({
                type: typings.extension.Extension.TYPES.REQUEST,
                id: utils.helpers.uuid(),
                ...request,
            });
            return res;
        }
        catch (e) {
            throw e;
        }
    };
    request = (request) => {
        try {
            let id = utils.helpers.uuid();
            this.#fire({
                type: typings.extension.Extension.TYPES.REQUEST,
                id,
                ...request,
            });
            return id;
        }
        catch (e) {
            throw e;
        }
    };
}
export default Api;
