import Api from './api';
import Mount from './mount';
import Browser from './browser';
import Env from './env';
import typings from '@crossmarkio/typings';
import EventEmitter, { EventManager } from './events';
import Session from './session';
class Sdk extends EventEmitter {
    #mount;
    api;
    session;
    browser;
    env;
    constructor() {
        super();
        this.browser = Browser;
        this.env = Env;
        this.session = new Session(this);
        this.#mount = new Mount(this);
        this.api = new Api();
        new EventManager(this);
    }
    signIn = (hex) => this.api.request({
        command: typings.extension.Extension.COMMANDS.SIGN,
        data: {
            tx: { TransactionType: 'SignIn' },
            hex,
        },
    });
    signInAndWait = (hex) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.SIGN,
        data: {
            tx: { TransactionType: 'SignIn' },
            hex,
        },
    });
    sign = (tx, opts) => this.api.request({
        command: typings.extension.Extension.COMMANDS.SIGN,
        data: {
            tx,
            opts,
        },
    });
    signAndWait = (tx, opts) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.SIGN,
        data: {
            tx,
            opts,
        },
    });
    submit = (address, txblob, opts) => this.api.request({
        command: typings.extension.Extension.COMMANDS.SUBMIT,
        data: {
            address,
            txblob,
            opts,
        },
    });
    submitAndWait = (address, txblob, opts) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.SUBMIT,
        data: {
            address,
            txblob,
            opts,
        },
    });
    signAndSubmit = (tx, opts) => this.api.request({
        command: typings.extension.Extension.COMMANDS.SIGNANDSUBMIT,
        data: {
            tx,
            opts,
        },
    });
    signAndSubmitAndWait = (tx, opts) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.SIGNANDSUBMIT,
        data: {
            tx,
            opts,
        },
    });
    bulkSign = (txns, opts) => this.api.request({
        command: typings.extension.Extension.COMMANDS.BULKSIGN,
        data: {
            txns,
            opts,
        },
    });
    bulkSignAndWait = (txns, opts) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.BULKSIGN,
        data: {
            txns,
            opts,
        },
    });
    bulkSubmit = (address, txblobs, opts) => this.api.request({
        command: typings.extension.Extension.COMMANDS.BULKSUBMIT,
        data: {
            address,
            txblobs,
            opts,
        },
    });
    bulkSubmitAndWait = (address, txblobs, opts) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.BULKSUBMIT,
        data: {
            address,
            txblobs,
            opts,
        },
    });
    bulkSignAndSubmit = (txns, opts) => this.api.request({
        command: typings.extension.Extension.COMMANDS.BULK,
        data: {
            txns,
            opts,
        },
    });
    bulkSignAndSubmitAndWait = (txns, opts) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.BULK,
        data: {
            txns,
            opts,
        },
    });
    getResponse = (id) => this.session.responses.get(id);
    connect = (timeout) => this.#mount.loop(timeout);
    isConnected = () => this.#mount.isCrossmark;
    isLocked = () => this.api.request({
        command: typings.extension.Extension.COMMANDS.IS_LOCKED,
    });
    isLockedAndWait = () => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.IS_LOCKED,
    });
    isOpen = () => this.session.isOpen;
    version = () => this.api.request({
        command: typings.extension.Extension.COMMANDS.VERSION,
    });
    versionAndWait = () => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.VERSION,
    });
    verify = (hex) => this.api.request({
        command: typings.extension.Extension.COMMANDS.VERIFY,
        data: {
            hex,
        },
    });
    verifyAndWait = (hex) => this.api.awaitRequest({
        command: typings.extension.Extension.COMMANDS.VERIFY,
        data: {
            hex,
        },
    });
    getAddress = () => this.session.address;
    getNetwork = () => this.session.network;
    getUser = () => this.session.user;
}
const Types = typings.extension.Extension.TYPES;
const Models = typings.crossmark.models.Models;
const Events = typings.extension.Extension.EVENTS;
export { Types, Models, Events };
export default Sdk;
