import Env from './env';
export const Scheme = {
    get: (target, prop) => {
        if (prop.startsWith('_'))
            throw new Error('Access denied');
        if (Env.isMobile)
            throw new Error('Crossmark only available from desktop');
        let value = target[prop];
        return typeof value === 'function' ? value.bind(target) : value;
    },
    set: (target, prop, val) => {
        if (prop.startsWith('_')) {
            throw new Error('Access denied');
        }
        else {
            target[prop] = val;
            return true;
        }
    },
    deleteProperty: (target, prop) => {
        if (prop.startsWith('_')) {
            throw new Error('Access denied');
        }
        else {
            delete target[prop];
            return true;
        }
    },
    ownKeys: (target) => {
        return Object.keys(target).filter((key) => !key.startsWith('_'));
    },
};
export default Scheme;
