import typings from '@crossmarkio/typings';
class Session {
    sdk;
    user;
    network;
    address;
    isOpen = false;
    lastPing;
    state = "unactive";
    responses = new Map();
    constructor(sdk) {
        this.sdk = sdk;
        this.sdk.on(typings.extension.Extension.EVENTS.PING, this.handlePing);
        this.sdk.on(typings.extension.Extension.EVENTS.RESPONSE, this.handleResponse);
        this.sdk.on(typings.extension.Extension.EVENTS.USER_CHANGE, this.handleUserChange);
        this.sdk.on(typings.extension.Extension.EVENTS.NETWORK_CHANGE, this.handleNetworkChange);
        this.sdk.on(typings.extension.Extension.EVENTS.OPEN, this.handleOpen);
        this.sdk.on(typings.extension.Extension.EVENTS.CLOSE, this.handleClose);
        this.sdk.on(typings.extension.Extension.EVENTS.SIGNOUT, this.handleSignOut);
        this.sdk.on('detected', this.handleDetect);
    }
    handleDetect = async () => {
        this.network = (await this.sdk.api.awaitRequest({
            command: typings.extension.Extension.COMMANDS.NETWORK,
        })).response.data.network;
        this.user = (await this.sdk.api.awaitRequest({
            command: typings.extension.Extension.COMMANDS.USER,
        })).response.data.user;
        this.address = (await this.sdk.api.awaitRequest({
            command: typings.extension.Extension.COMMANDS.ADDRESS,
        })).response.data.address;
        if (this.network && this.user && this.address)
            this.state = "active";
    };
    handlePing = () => (this.lastPing = Date.now());
    handleClose = () => (this.isOpen = false);
    handleOpen = () => (this.isOpen = true);
    handleSignOut = () => {
        this.state = "unactive";
        this.address = undefined;
        this.network = undefined;
        this.user = undefined;
    };
    handleNetworkChange = (network) => {
        this.network = network.network;
    };
    handleUserChange = (user) => {
        this.user = user.user;
        this.address = undefined;
    };
    handleResponse = (resp) => {
        if ('address' in resp.response.data) {
            this.state = "active";
            this.address = resp.response.data.address;
        }
        if ('network' in resp.response.data) {
            this.network = resp.response.data.network;
        }
        if ('user' in resp.response.data) {
            this.user = resp.response.data.user;
        }
        this.responses.set(resp.request.id, resp);
    };
}
Session.prototype['user'] = undefined;
Session.prototype['network'] = undefined;
Session.prototype['address'] = undefined;
export default Session;
